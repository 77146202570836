import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { SeoData, SizeGrid } from "components";
import { H2, P, Container, SmallContainer } from "styles";
import { StaticImage } from "gatsby-plugin-image";
import {motion} from 'framer-motion';

const MaskSizingPage = ({ data }) => {
  const { products } = data.allMarkdownRemark.edges[0].node.frontmatter;
  return (
    <motion.main
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ delay:.25, duration: .75 }}
  >
      <SeoData pageSpecificTitle="Mask Sizing" pageSpecificDescription="Get help choosing which size AeroChamber Plus® Flow-Vu® mask device would be the best fit – find out more or contact Teva: T: +44 207 540 7117 or E: Medinfo:@tevauk.com" />
      <SmallContainer id="patients">
        <H2 centered style={{ marginBottom: "5rem" }}>
          Mask Sizing
        </H2>
        <P centered>
          The below measurements can offer support in choosing which size
          AeroChamber Plus<sup>®</sup> Flow-Vu<sup>®</sup> mask device would be the best fit. If you would like more information on what size is right for you please contact your local healthcare professional.
        </P>
      </SmallContainer>
      <Container>
        <SizeGrid products={products} />
      </Container>
      <Container>
        <HowToFit>
          <StaticImage
            src="../../images/mask-sizing.png"
            alt="Sizing Chart"
            placeholder="blurred"
            layout="fixed"
            loading="lazy"
            objectFit="contain"
            className="product-image"
          />
          <div>
            <H2>How to Fit Mask Perfectly</H2>
            <P>
              The top of the mask should sit on the bridge of the nose and the
              bottom should rest between the bottom of the lips and chin.
            </P>
          </div>
        </HowToFit>
      </Container>
    </motion.main>
  );
};

const HowToFit = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 5rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0;
  margin-bottom:5rem;
  padding: 3rem;
  ${H2}, ${P}{
    text-align:center;
  }
  .gatsby-image-wrapper {
    width: 16rem !important;
    height: 16rem !important;
    height: 100%;
    margin: 0 auto;
    margin-bottom: 2rem;
    z-index: 2;
    border-radius: 50%;
    border: 0.5rem solid var(--teva-blue);
    background: "white";
    box-shadow: 0 0 0 0 rgba(142, 173, 255, 0.1);
    animation: pulse 1.5s infinite;
    transform-origin: center;
    transition-duration: 0.5s;
    img {
      width: 110%;
      margin-left: -5%;
      max-width: 120%;
      height: auto;
      object-fit: cover !important;
    }
    @keyframes pulse {
      0% {
        box-shadow: 0 0 0 0.2rem rgba(0, 127, 200, 0.2),
          0 0 0 0.4rem rgba(0, 127, 200, 0.1);
      }

      50% {
        box-shadow: 0 0 0 0.375rem rgba(0, 127, 200, 0.2),
          0 0 0 0.75rem rgba(0, 127, 200, 0.1);
      }

      100% {
        box-shadow: 0 0 0 0.2rem rgba(0, 127, 200, 0.2),
          0 0 0 0.4rem rgba(0, 127, 200, 0.1);
      }
    }
  }
  @media (min-width: 768px) {
    grid-template-columns: 1fr 2fr;
    ${H2}, ${P}{
      text-align:left;
    }
  }
`;

export const query = graphql`
  query MaskSizingQuery {
    allMarkdownRemark(filter: { frontmatter: { title: { eq: "products" } } }) {
      edges {
        node {
          id
          frontmatter {
            products {
              description
              id
              link
              images {
                image {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                  }
                }
              }
              shortName
              ageRange
              name
              hasMask
              height
              width
              depth
              mainImg {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                }
              }
              croppedImg {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                }
              }
              maskImg {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default MaskSizingPage;
